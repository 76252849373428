// Still haven't setup primary and secondary colors, just using default semantic colors but adding for a few sections
// $primaryColor: #;
$secondaryColor: #000;

// $bgDarkMode: #454545;
$bgDarkMode: #000000d1;
$textDarkMode: #f5f5f5;
$bgHome: #355d66;
$bgSkills: #8884d8;
// $bgSkills: #10a3a3;
// $bgSkills: #798344;
$bgWork: #772626;
$bgAbout: #798344;

body {
  --bgDarkMode: #000000d1;
  --textDarkMode: #f5f5f5;
  --bgHome: #355d66;
  --bgSkills: #8884d8;
  --bgWork: #772626;
  --bgAbout: #798344;
}
