// Theme later - check out resume figma page for that dripping logo (dark mode)
// #root {
//   background-color: rgb(34, 34, 34);
// }

.ui.container .home {
  z-index: 0;
  padding-top: 10%;

  p {
    font-size: 1.33em;
  }

  .homeDetails {
    margin: 0;

    h3 {
      margin: 2px;
    }

    h5 {
      margin: 0;
    }
  }
}
