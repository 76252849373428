@import '../../utilities/variables.scss';

.skills {
  .ui.tab {
    background: rgba($color: white, $alpha: 0.6);
  }
  .ui.message {
    // color: #10a3a3;
    color: $bgSkills;
  }

  .lineChart,
  .radarChart {
    width: 100%;
    height: 750px;

    @media only screen and (max-width: 600px) {
      height: 300px;
    }
  }

  .radarChart {
    .recharts-default-legend {
      display: none;
    }

    .recharts-surface > .recharts-layer {
      .recharts-radar,
      .recharts-radar-polygon {
        stroke: $bgSkills;
        fill: $bgSkills;
      }
    }
  }

  &.skillsMobile {
    padding: 15px;
    .divider {
      width: 100%;
      margin: 0;
    }
  }
}
